import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import useClientSide from 'hooks/useClientSide';
import useMediaQuery from 'hooks/useMediaQuery';

import { useTopbar } from 'providers/TopbarProvider';

const SPACE_HEIGHT_BETWEEN_SIDEBAR_COMPONENTS_IN_PX = 16;
const COMPENSATION_GAP_TO_LAST_FIXED_COMPONENT = 2;
const RIGHT = 'right';
const LEFT = 'left';

const sumRefsHeightAddingSpacings = (refsToBeIgnoredBeforeFixing) => {
  if (refsToBeIgnoredBeforeFixing.length === 0) {
    return 0;
  }

  const refsWithElement = refsToBeIgnoredBeforeFixing.filter(
    ({ current }) => current && !current?.retry
  );

  return refsWithElement.reduce(
    (acc, { current: elem }, index) =>
      (index > 0 ? SPACE_HEIGHT_BETWEEN_SIDEBAR_COMPONENTS_IN_PX : 0) +
      acc +
      elem?.offsetHeight,
    COMPENSATION_GAP_TO_LAST_FIXED_COMPONENT
  );
};

const Sidebar = ({
  className,
  children,
  isFixedAfterIgnoredRefs = true,
  position = RIGHT,
  refsToBeIgnoredBeforeFixing = [],
}) => {
  const isClientSide = useClientSide();
  const { isXl } = useMediaQuery();
  const {
    isTopbarActive,
    topbarDesktop: {
      constants: { topbarMainHeight, topbarBottomHeight },
    },
  } = useTopbar();

  const getCurrentTopbarOffset = useCallback(() => {
    if (!isXl || !isClientSide) {
      return null;
    }

    return (
      topbarMainHeight +
      topbarBottomHeight -
      sumRefsHeightAddingSpacings(refsToBeIgnoredBeforeFixing)
    );
  }, [isClientSide, isTopbarActive, topbarMainHeight, topbarBottomHeight]);

  return (
    <aside
      className={twMerge(
        'col-span-4 row-start-4 row-end-5 mt-4 flex h-auto flex-col transition-all duration-300 ease-in md:col-span-12 xl:col-span-3 xl:row-start-1 xl:row-end-3 xl:mt-0 xl:h-fit',
        position === RIGHT && 'xl:col-start-10',
        position === LEFT && 'xl:col-start-1',
        isFixedAfterIgnoredRefs ? 'xl:sticky' : 'xl:relative',
        className
      )}
      style={{
        top:
          isXl && isFixedAfterIgnoredRefs
            ? `${getCurrentTopbarOffset()}px`
            : '0px',
      }}
    >
      {children}
    </aside>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  isFixedAfterIgnoredRefs: PropTypes.bool,
  refsToBeIgnoredBeforeFixing: PropTypes.arrayOf(PropTypes.shape()),
  position: PropTypes.oneOf([LEFT, RIGHT]),
};

export default Sidebar;
